<template>
    <v-input
        :label="labelText"
        class="trans-textarea-input"
        :error-messages="fieldErrors"
    >
        <template slot="default">
            <div class="w-100">
                <v-tabs v-model="selectedLang">
                    <v-tab v-for="lang in languages" :key="lang">
                        {{ lang }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="selectedLang">
                    <v-tab-item
                        v-for="lang in languages"
                        :key="lang"
                        :transition="false"
                        :reverse-transition="false"
                    >
                        <text-input
                            v-validate="validators"
                            :value="value[lang] ? value[lang][field] : ''"
                            :name="name + '_' + lang"
                            :data-vv-as="$t(label)"
                            @input="onInput($event, lang)"
                        ></text-input>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </template>
    </v-input>
</template>

<script>
import TextInput from '@/components/form/Inputs/TextInput'
import TranslatableInputMixin from '@/components/form/Inputs/InputMixins/TranslatableInputMixin'

export default {
    components: {
        TextInput,
    },
    mixins: [TranslatableInputMixin],
}
</script>

<style lang="scss">
/* Look in ./TransTextareaInput.vue  */
</style>
