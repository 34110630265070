import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import CloneDeep from 'lodash/cloneDeep'
import BaseInputMixin from './BaseInputMixin'

export default {
    mixins: [BaseInputMixin],
    inject: {
        $validator: 'formValidator',
    },
    data() {
        return {
            selectedLang: null,
        }
    },
    props: {
        field: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            required: true,
        },
        validators: {
            type: [String, Object],
            default: null,
        },
        disabled: Boolean,
    },
    computed: {
        ...mapGetters({
            languages: configType.getters.LOCALES,
        }),
        fieldErrors() {
            let errors = []

            this.languages.forEach((lang) => {
                const langErrors = this.errors.collect(this.name + '_' + lang)
                langErrors.forEach((error) => {
                    errors.push('(' + lang + ') ' + error)
                })
            })

            return errors
        },
    },
    methods: {
        onInput(input, lang) {
            const value = CloneDeep(this.value)

            if (!value[lang]) {
                value[lang] = {}
            }

            value[lang][this.field] = input

            this.$emit('input', value)
        },
    },
}
