import isEqual from 'lodash/isEqual'
import SelectApiClientType from '@/api/SelectApiClientType'
import ApiClientType from '@/api/RestApiType'
import cloneDeep from 'lodash/cloneDeep'
import Notification from '@/services/Notification/Notification'
import MapperType from '@/services/mapper/MapperType'
import ActiveTabMixin from './ActiveTabMixin'
import BaseForm from '@/components/form/BaseForm'
import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import FormThreeColRow from '@/components/form/FormThreeColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import PasswordInput from '@/components/form/Inputs/PasswordInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import SelectInput from '@/components/form/Inputs/SelectInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'
import FormOneColRow from '@/components/form/FormOneColRow'
import FormTab from '@/components/form/FormTab'
import FormTabs from '@/components/form/FormTabs'
import AddressMapInput from '@/components/form/Inputs/google/AddressMapInput'
import AddressAutocompleteInput from '@/components/form/Inputs/google/AddressAutocompleteInput'
import ReadOnlyText from '@/components/form/Inputs/ReadOnlyText'
import TextareaInput from '@/components/form/Inputs/TextareaInput'
import TagInput from '@/components/form/Inputs/TagInput'
import CurrencyInput from '@/components/form/Inputs/CurrencyInput'
import TransTextareaInput from '@/components/form/Inputs/TransTextareaInput'
import TransTextInput from '@/components/form/Inputs/TransTextInput'

export default {
    components: {
        BaseForm,
        FormPanel,
        FormTwoColRow,
        FormThreeColRow,
        TextInput,
        PasswordInput,
        CheckboxInput,
        EntitySelectInput,
        SelectInput,
        DatePickerInput,
        FormOneColRow,
        FormTab,
        FormTabs,
        AddressMapInput,
        AddressAutocompleteInput,
        ReadOnlyText,
        TextareaInput,
        TagInput,
        CurrencyInput,
        TransTextareaInput,
        TransTextInput,
    },
    mixins: [ActiveTabMixin],
    provide() {
        return {
            formValidator: this.$validator,
        }
    },
    props: ['entityId'],
    data() {
        return {
            form: {},
            entity: '',
            editRoute: '',
            listRoute: '',
            disabled: false,
            mapperType: MapperType,
            selectApiClientType: SelectApiClientType,
            apiClientType: ApiClientType,
            tabErrorsContainer: {},
        }
    },
    computed: {
        title() {
            return this.form.name
        },
        tabErrors() {
            const tabErrors = {}

            Object.keys(this.tabErrorsContainer).forEach((tabName) => {
                Object.keys(this.tabErrorsContainer[tabName]).forEach(
                    (propertyName) => {
                        if (!tabErrors[tabName]) {
                            tabErrors[tabName] = 0
                        }

                        if (
                            this.tabErrorsContainer[tabName][propertyName] > 0
                        ) {
                            tabErrors[tabName]++
                        }
                    }
                )
            })

            return tabErrors
        },
    },
    directives: {
        tabError(el, binding, vNode) {
            if (!vNode.componentOptions.propsData.name) {
                console.warn('FormMixin, missing name', el)

                return
            }

            if (!vNode.componentOptions.propsData.errorMessages) {
                console.warn(
                    'FormMixin, missing errorMessages for ' +
                        vNode.componentOptions.propsData.name
                )
                return
            }

            const inputName = vNode.componentOptions.propsData.name
            const errorCount =
                vNode.componentOptions.propsData.errorMessages.length
            const tabName = binding.arg

            const errorsClone = cloneDeep(vNode.context.tabErrorsContainer)

            if (!errorsClone[tabName]) {
                errorsClone[tabName] = {}
            }

            errorsClone[tabName][inputName] = errorCount

            if (!isEqual(errorsClone, vNode.context.tabErrorsContainer)) {
                vNode.context.tabErrorsContainer = errorsClone
            }
        },
    },
    methods: {
        created(data) {
            this.disabled = true
            Notification.entityCreated(this.entity, this.title)
            if (this.$route.query.redirectTo) {
                this.$router.push(this.$route.query.redirectTo)
            } else {
                this.$router.push({
                    name: this.editRoute,
                    params: this.editRedirectParams(data),
                    query: { tab: this.$route.query.tab || 0 },
                })
            }
        },
        updated() {
            Notification.entityUpdated(this.entity, this.title)
            if (this.$route.query.redirectTo) {
                this.$router.push(this.$route.query.redirectTo)
            }
        },
        cancelled() {
            this.$router.push({
                name: this.listRoute,
                params: this.listRedirectParams(),
            })
        },
        deleted() {
            Notification.entityDeleted(this.entity, this.title)
            this.$router.push({
                name: this.listRoute,
                params: this.listRedirectParams(),
            })
        },
        mergeTabErrorsContainer(tabErrorsContainer) {
            this.tabErrorsContainer = cloneDeep(
                Object.assign(this.tabErrorsContainer, tabErrorsContainer)
            )
        },
        // to be overridden by forms when param names change
        editRedirectParams: (data) => ({ id: data.id }),
        listRedirectParams: () => ({}),
    },
}
