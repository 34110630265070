export default {
    computed: {
        activeTab: {
            get() {
                return parseInt(this.$route.query.tab) || 0
            },
            set(val) {
                this.$router.replace({
                    query: {
                        ...this.$route.query,
                        tab: val,
                    },
                })
            },
        },
    },
}
