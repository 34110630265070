<template>
    <v-text-field
        :value="value"
        :name="name"
        :label="labelText"
        :error-messages="errorMessages"
        :autocomplete="autocomplete"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'visibility' : 'visibility_off'"
        @input="$emit('input', $event)"
        @click:append="showPassword = !showPassword"
    ></v-text-field>
</template>

<script>
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'

export default {
    mixins: [ErrorMsgInputMixin],
    props: {
        value: {
            type: String,
            default: null,
        },
        autocomplete: {
            type: String,
            default: 'off',
        },
    },
    data() {
        return {
            showPassword: false,
        }
    },
}
</script>
