<template>
    <div>
        <v-row>
            <v-col>
                <p>
                    <span class="font-weight-black"
                        >Custom Export Parameters</span
                    >
                </p>
                <v-btn @click="items.push({ name: '', type: '' })"
                    ><span class="material-icons">add</span></v-btn
                >
            </v-col>
        </v-row>
        <template v-for="(item, index) in items">
            <v-row v-if="index % 3 === 0" :key="`row-${index}`">
                <v-col
                    v-for="(subItem, subIndex) in items.slice(index, index + 3)"
                    :key="`col-${index + subIndex}`"
                    cols="4"
                >
                    <v-card outlined tile>
                        <v-card-text>
                            <div class="text-right">
                                <button
                                    type="button"
                                    @click="removeItem(index + subIndex)"
                                >
                                    <span class="material-icons">delete</span>
                                </button>
                            </div>
                            <form-one-col-row>
                                <text-input
                                    v-model="subItem.name"
                                    v-validate.immediate="
                                        'required|min:2|max:50|alpha_num'
                                    "
                                    :name="inputName('name', subIndex, index)"
                                    :error-messages="
                                        errors.collect(
                                            inputName('name', subIndex, index)
                                        )
                                    "
                                    :data-vv-as="$t('labels.name')"
                                    :label="$t('labels.name')"
                                    autocomplete="off"
                                ></text-input>
                            </form-one-col-row>
                            <form-one-col-row>
                                <entity-select-input
                                    v-model="subItem.type"
                                    v-validate.immediate="'required'"
                                    label="labels.type"
                                    :name="inputName('type', subIndex, index)"
                                    :entity="
                                        selectApiClientType.CUSTOM_EXPORT_PARAMETER_TYPE
                                    "
                                    translate-items
                                    :error-messages="
                                        errors.collect(
                                            inputName('type', subIndex, index)
                                        )
                                    "
                                    :data-vv-as="$t('labels.type')"
                                ></entity-select-input>
                            </form-one-col-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import TextInput from '@/components/form/Inputs/TextInput.vue'
import FormOneColRow from '@/components/form/FormOneColRow.vue'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput.vue'
import SelectApiClientType from '@/api/SelectApiClientType'
import _ from 'lodash'

export default {
    components: { EntitySelectInput, FormOneColRow, TextInput },
    props: {
        value: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            selectApiClientType: SelectApiClientType,
            items: [],
        }
    },
    watch: {
        value: {
            handler(newValue) {
                if (_.isEqual(newValue, this.items)) {
                    return
                }

                this.items = _.cloneDeep(newValue)

                if (this.items === null) {
                    this.items = []
                }
            },
            immediate: true,
        },
        items: {
            handler(newValue) {
                if (_.isEqual(newValue, this.value)) {
                    return
                }

                this.$emit('input', _.cloneDeep(newValue))
            },
            deep: true,
        },
    },
    methods: {
        removeItem(index) {
            this.items.splice(index, 1)
        },
        inputName(prefix, subIndex, index) {
            return `${prefix}_${index}_${subIndex}`
        },
    },
}
</script>
